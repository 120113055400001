import { StepWizardChildProps } from 'react-step-wizard';
import React, { ComponentClass, FC, useEffect, useState } from 'react';
import { TextVariant } from '@naf/text';
import S from '../../../styles/becomeAMember/StyledBecomeAMember';
import { useQueryParams } from '../../../hooks/useQueryParameters';
import { updateUrlQueryValues } from '../../../utils/updateUrlQueryValues';

export const Progressbar: FC<
  Partial<Partial<StepWizardChildProps>> & {
    steps: {
      name: string;
      step: FC<Partial<StepWizardChildProps>> | ComponentClass<Partial<StepWizardChildProps>> | string;
    }[];
  }
> = ({ totalSteps, currentStep, goToStep, steps }) => {
  const { wizardStep } = useQueryParams() || {};
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true);
  }, []);

  const isOverflown = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      return element.scrollWidth > element.clientWidth;
    }
    return false;
  };

  useEffect(() => {
    const step = wizardStep && parseInt(wizardStep, 10);
    if (goToStep && step) goToStep(step);
  }, [wizardStep, goToStep]);

  useEffect(() => {
    if (currentStep && totalSteps && currentStep !== totalSteps && currentStep !== 1)
      updateUrlQueryValues.updateWizardStep(currentStep.toFixed(0));
  }, [currentStep, totalSteps]);

  return (
    <>
      <S.Counter variant={TextVariant.Small}>{`Steg ${currentStep} av ${steps.length}`}</S.Counter>
      <S.Labels>
        {steps &&
          currentStep &&
          steps.map((stepObject, i) => (
            <S.Label
              tabIndex={0}
              $isCurrent={i === currentStep - 1}
              $totalSteps={totalSteps || 0}
              key={`label-${stepObject.name}`}
              $isOverflown={isMounted ? isOverflown(stepObject.name) : false}
            >
              <S.HoverBlock variant={TextVariant.Small}>{stepObject.name}</S.HoverBlock>
              <span id={stepObject.name}>{stepObject.name}</span>
            </S.Label>
          ))}
      </S.Labels>
      <S.ProgressBarElementsWrapper>
        {steps &&
          currentStep &&
          steps.map((stepObject, i) => (
            <S.ProgressBarElement
              $isActive={i < currentStep}
              $totalSteps={totalSteps}
              key={`Progressbar-${stepObject.name}`}
            />
          ))}
      </S.ProgressBarElementsWrapper>
    </>
  );
};
